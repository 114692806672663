import { memo, lazy } from "react";
import { Route } from "react-router-dom";
import { QueryClient } from "react-query";
import { Admin, Resource, CustomRoutes } from "react-admin";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import Login from "./Auth/Login";
import GetAuthProvider from "./Auth/AuthProvider";
import { i18nProvider } from "./i18nProvider";
import DataProvider from "./DataProvider";
import GetAuthToken from "./Auth/GetAuthToken";

const DataExtraction = lazy(
  () => import("Pages/DataExtraction/DataExtraction")
);
const CustomLayout = lazy(() => import("Layout/CustomLayout"));
const ImportQuote = lazy(() => import("Pages/ImportQuote/ImportQuote"));
const ProtectedRoute = lazy(() => import("Components/ProtectedRoute"));
const PoliciesList = lazy(() => import("Pages/Policies/PoliciesList"));
const PolicyDetail = lazy(() => import("Pages/Policies/PolicyDetail"));
const QuotesList = lazy(() => import("Pages/Quotes/QuotesList"));
const QuotesDetail = lazy(() => import("Pages/Quotes/QuotesDetail"));
const UsersList = lazy(() => import("Pages/Users/UsersList"));
const UsersDetail = lazy(() => import("Pages/Users/UsersDetail"));
const UsersCommission = lazy(
  () => import("Pages/Users/UserCommission/UsersCommission")
);
const UsersCommissionTemplate = lazy(
  () => import("Pages/Users/UserCommission/UsersCommissionTemplate")
);
const CommissionsHomePage = lazy(
  () => import("Pages/Commissions/CommissionsHomePage")
);
const CommissionsTemplate = lazy(
  () => import("Pages/Commissions/CommissionsTemplate")
);

declare module "@mui/material/styles" {
  interface Palette {
    gray: Palette["primary"];
  }
  interface PaletteOptions {
    gray?: PaletteOptions["primary"];
  }
}

const muiTheme = createTheme({
  palette: {
    primary: {
      main: "#035e7e",
    },
    secondary: {
      main: "#afca0b",
    },
    gray: {
      main: "#787878",
      dark: "#A8AAAF",
    },
  },
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  const MemoizedCustomLayout = memo(CustomLayout);

  return (
    <MuiThemeProvider theme={muiTheme}>
      <UnauthenticatedTemplate>
        <Login />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <ToastContainer />
        <GetAuthToken />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Admin
            layout={MemoizedCustomLayout}
            i18nProvider={i18nProvider}
            dataProvider={DataProvider}
            authProvider={GetAuthProvider()}
            queryClient={queryClient}
            theme={muiTheme}
          >
            {(permissions) => {
              const showPolicies =
                permissions?.permissions?.policy?.includes("read") &&
                process.env.REACT_APP_API_ENV === "dev";
              const showQuotes =
                permissions?.permissions?.quote?.includes("read") &&
                process.env.REACT_APP_API_ENV === "dev";
              const showDataExtraction =
                permissions?.permissions?.aniaExtraction?.includes("execute");
              const showImportQuotes =
                permissions?.permissions?.quote?.includes("read") &&
                permissions?.permissions?.quote?.includes("write") &&
                process.env.REACT_APP_API_ENV === "dev";
              const showUsers =
                permissions?.permissions?.user?.includes("read") &&
                process.env.REACT_APP_API_ENV !== "prod";
              const showCommissions = process.env.REACT_APP_API_ENV === "dev";

              return (
                <>
                  {showPolicies && (
                    <Resource name="policies" list={PoliciesList} />
                  )}
                  {showQuotes && (
                    <Resource name="quotations" list={QuotesList} />
                  )}
                  {showUsers && <Resource name="Customers" list={UsersList} />}
                  <CustomRoutes>
                    <Route
                      path="/ania-data-extraction"
                      element={
                        <ProtectedRoute
                          showPage={showDataExtraction}
                          component={<DataExtraction />}
                        />
                      }
                    />
                    <Route
                      path="/policies/:product/:id"
                      element={
                        <ProtectedRoute
                          showPage={showPolicies}
                          component={<PolicyDetail />}
                        />
                      }
                    />
                    <Route
                      path="/quotations/:id"
                      element={
                        <ProtectedRoute
                          showPage={showQuotes}
                          component={<QuotesDetail />}
                        />
                      }
                    />
                    <Route
                      path="/customers/:id"
                      element={
                        <ProtectedRoute
                          showPage={showUsers}
                          component={<UsersDetail />}
                        />
                      }
                    />
                    <Route
                      path="/customers/:id/commissions"
                      element={
                        <ProtectedRoute
                          showPage={showUsers}
                          component={<UsersCommission />}
                        />
                      }
                    />
                    <Route
                      path="/customers/:id/commissions/:product"
                      element={
                        <ProtectedRoute
                          showPage={showUsers}
                          component={<UsersCommissionTemplate />}
                        />
                      }
                    />
                    <Route
                      path="/import-quote"
                      element={
                        <ProtectedRoute
                          showPage={showImportQuotes}
                          component={<ImportQuote />}
                        />
                      }
                    />
                    <Route
                      path="/commissions"
                      element={
                        <ProtectedRoute
                          showPage={showCommissions}
                          component={<CommissionsHomePage />}
                        />
                      }
                    />
                    <Route
                      path="/commissions/:product"
                      element={
                        <ProtectedRoute
                          showPage={showUsers}
                          component={<CommissionsTemplate />}
                        />
                      }
                    />
                  </CustomRoutes>
                </>
              );
            }}
          </Admin>
        </LocalizationProvider>
      </AuthenticatedTemplate>
    </MuiThemeProvider>
  );
};

export default App;
