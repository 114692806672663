export enum IApimValues {
  mainpolicyapi = "mainpolicyapi",
  vehicle = "vehicle",
  customer = "customer",
  motorQuotation = "motor-quotation",
  utilities = "utilities",
  geo = "geo",
  adminquotationapi = "adminquotationapi",
  customers = "customers",
}

export const aniaDataExtraction_base_url = {
  dev: "https://fai-vehicle-api-dev.azurewebsites.net",
  staging: "https://vehicle-api-test.azurewebsites.net",
  prod: "https://fai-vehicle-api-v2.azurewebsites.net",
};

export const apim_base_url = {
  dev: "https://apidev.lumesiainsurance.com",
  staging: "https://apitest.lumesiainsurance.com",
  prod: "https://api.lumesiainsurance.com",
};

export const API_BASE_URL = (value: IApimValues) => {
  if (value === IApimValues.customers) {
    return `${apim_base_url[process.env.REACT_APP_API_ENV as string]}/api/v1`;
  }
  return `${
    apim_base_url[process.env.REACT_APP_API_ENV as string]
  }/${value}/api/v1`;
};

interface IfetchCall {
  method: "GET" | "POST" | "PUT" | "DELETE";
  URL: string;
  body?: any;
  cleanResponse?: boolean;
  authToken?: string;
  sendFile?: boolean;
}

const checkHeaders = (sendFile, authToken) => {
  if (sendFile) {
    return { Authorization: `Bearer ${authToken}` };
  }

  return {
    Authorization: `Bearer ${authToken}`,
    "Content-Type": "application/json",
    Accept: "application/json",
  };
};

async function authCall({
  method,
  URL,
  body,
  cleanResponse,
  authToken,
  sendFile,
}: IfetchCall) {
  try {
    const response = await fetch(URL, {
      method,
      headers: checkHeaders(sendFile, authToken) as any,
      body: sendFile ? body : JSON.stringify(body),
    });

    // error response
    if (response.status !== 200) {
      const errorDetails = await response.json();
      return {
        errorDetails,
        error: true,
      };
    }
    // clean success response
    if (cleanResponse) {
      return response;
    }
    // json success response
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error, "error");
    return { error: true };
  }
}

export const fetchCall = async ({
  method,
  URL,
  body,
  cleanResponse,
  sendFile,
}: IfetchCall) => {
  const accessToken = sessionStorage.getItem(`authToken`);
  if (accessToken) {
    return authCall({
      method,
      URL,
      body,
      cleanResponse,
      authToken: accessToken,
      sendFile,
    });
  }
};
